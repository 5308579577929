var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-intersection',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter),expression:"filter"}],attrs:{"transition":"slide-down"}},[_c('q-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onFilterReports.apply(null, arguments)},"reset":_vm.clearFilter}},[(_vm.filter)?_c('q-card',{staticClass:"q-mb-sm",attrs:{"flat":"","bordered":""}},[_c('q-toolbar',[_c('q-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('filter_by')))]),_c('q-btn',{attrs:{"flat":"","round":"","dense":"","icon":"mdi-close"},on:{"click":function($event){_vm.filter = false}}})],1),_c('q-card-section',{staticClass:"q-pt-none row q-col-gutter-md"},[_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"outlined":"","type":"text","label":_vm.$t('request_number')},model:{value:(_vm.params.orderId),callback:function ($$v) {_vm.$set(_vm.params, "orderId", $$v)},expression:"params.orderId"}})],1),_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"outlined":"","type":"text","label":"ID da conversão"},model:{value:(_vm.params.actionId),callback:function ($$v) {_vm.$set(_vm.params, "actionId", $$v)},expression:"params.actionId"}})],1),_c('div',{staticClass:"col-4"},[_c('q-select',{attrs:{"use-input":"","input-debounce":"200","options":_vm.campaignsFiltered,"label":"Campanha","option-label":"name","option-value":"id","outlined":""},on:{"filter":_vm.filterComboCampaigns},model:{value:(_vm.params.campaignId),callback:function ($$v) {_vm.$set(_vm.params, "campaignId", $$v)},expression:"params.campaignId"}})],1),_c('div',{staticClass:"col-6"},[_c('date-input',{attrs:{"buttons":"","outlined":"","label":_vm.$t('conversion_date'),"v-model":_vm.params.date,"clearable":"","range":"","today-btn":""},on:{"update":(value) => (_vm.params.date = value)}})],1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col"},[_c('q-select',{attrs:{"options":[
                    {
                      label: _vm.$t('approved'),
                      value: 2
                    },
                    {
                      label: _vm.$t('pending'),
                      value: 1
                    },
                    {
                      label: _vm.$t('refused'),
                      value: 0
                    }
                  ],"label":_vm.$t('conversion_status'),"outlined":""},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}})],1)])]),_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"use-input":"","input-debounce":"200","label":"Canal ID","outlined":"","type":"number"},model:{value:(_vm.params.siteId),callback:function ($$v) {_vm.$set(_vm.params, "siteId", $$v)},expression:"params.siteId"}})],1),_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"use-input":"","label":"Nome do canal","outlined":""},model:{value:(_vm.params.siteName),callback:function ($$v) {_vm.$set(_vm.params, "siteName", $$v)},expression:"params.siteName"}})],1),_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"use-input":"","label":"Nome do Afiliado","outlined":""},model:{value:(_vm.params.affiliateName),callback:function ($$v) {_vm.$set(_vm.params, "affiliateName", $$v)},expression:"params.affiliateName"}})],1),_c('div',{staticClass:"col-12 text-right"},[_c('q-btn',{staticClass:"q-px-md q-mx-md",attrs:{"color":"primary","label":_vm.$t('clear'),"no-caps":"","outline":"","type":"reset"}}),_c('q-btn',{staticClass:"q-px-md",attrs:{"color":"positive","label":_vm.$t('search'),"no-caps":"","unelevated":"","type":"submit"}})],1)])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }