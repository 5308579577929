<template>
  <q-layout>
    <q-page padding>
      <q-toolbar class="q-pr-none">
        <q-toolbar-title class="text-h5">{{ $t('conversion_report') }}</q-toolbar-title>
        <q-breadcrumbs>
          <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
          <q-breadcrumbs-el :label="$t('conversion_report')" exact />
        </q-breadcrumbs>
      </q-toolbar>
      <q-separator spaced />
      <AdvertiserHeaderConversionReports />
      <AdvertiserListConversionReports />
    </q-page>
  </q-layout>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
import AdvertiserHeaderConversionReports from '../../components/reports/advertiser/AdvertiserHeaderConversionReports';
import AdvertiserListConversionReports from '../../components/reports/advertiser/AdvertiserListConversionReports';

export default {
  name: 'AdvertiserConversionReports',
  components: {
    AdvertiserHeaderConversionReports,
    AdvertiserListConversionReports
  },
  mixins: [ReportMixin]
};
</script>
